.autores-page {
  .letterMark {
    margin: 46px 0 5px 1px;
    padding: 0 0 13px 0px;
    border-bottom: 1px solid @brand-primary;
  }

  .letterIdx {
    font-size: 18px;
    line-height: 38px;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    li {
      padding: 0;
      display: inline-block;
    }
    li+li:before {
      content: "/\A0";
      padding: 0 5px;
      color: #ccc;
    }
    a {
      padding: 10px;
    }
    .text-muted {
      color: #DDD;
    }
  }

  .authorList {
    margin-left: 0;
    &.nav > li > a:focus, &.nav > li > a:hover {
      background: transparent;
    }

  }
}
