@tailwind base;
input {
  @apply block w-full border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm;
}
label {
  @apply block text-sm font-medium text-gray-700;
}
button.button-primary, .button-primary {
  @apply inline-flex no-underline justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm;
}
button.button-secondary, .button-secondary {
  @apply inline-flex no-underline justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm;
}
a {
  @apply underline;
}
@tailwind components;
@tailwind utilities;

@gray-base:              #000;
@gray-darker:            lighten(@gray-base, 13.5%); // #222
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee

@brand-primary:         @gray-base;
@brand-primary-light:   lighten(@brand-primary, 15%);
@brand-success:         @gray-dark;
@brand-info:            @gray-light;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;

@text-color:            @gray-darker;

@font-family-sans-serif:  Work Sans, sans-serif;
@font-family-serif:       Chivo, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @gray-darker;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@border-radius-base:        1px;
@border-radius-large:       2px;
@border-radius-small:       1px;

#mainMenu  a {
  @apply no-underline;
}

@import "./components/autores";
@import "./components/modal-buy";

.read-section {
  @apply max-w-2xl;
}
.editable-region {
  @apply leading-7;
  color: var(--tw-prose-body);
  max-width: 65ch;
  p {
    @apply my-5;
  }
}
.highlight {
  @apply bg-yellow-300;
}

h2.title {
  @apply mt-8 text-3xl  border-b border-gray-300 pb-2;
}

h3.title {
  @apply text-xl leading-6;
}

.adminMenu li {
  @apply p-2 my-2;
  a {
    @apply no-underline;
  }
  &.active {
    @apply bg-black text-white;
    a {
      @apply text-white;
    }
  }
}
.admin {
  .table.table-edition {
    @apply w-full;
    td {
      @apply py-2 px-4;
    }
    tr {
      @apply border-b;
    }
    th {
      @apply font-normal text-right;
    }
    textarea {
      @apply w-full;
    }
  }
  .table.table-striped {
    @apply w-full border-separate;
    td {
      @apply px-4;
    }
    tr {

    }
    tbody > tr:nth-of-type(odd) {
      @apply bg-gray-100;
    }
  }
  h3 {
    @apply mb-4 text-2xl font-semibold;
  }
}
