#modal-buy {
  .formPayment {
    .only-paypal, .only-mercadopago {
      display: none;
    }

    &.paypal {
      .only-paypal {
        display: initial;
      }
    }
    &.mercadopago {
      .only-mercadopago {
        display: initial;
      }
    }
  }

}
